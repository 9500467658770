import { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { actionTypes as viewActionTypes } from 'actions/view';
import { createLoadingSelector } from 'reducers/ui';
import { entityLabelFormatterSelector } from 'selectors/entities';
import { isComparativeViewFacet } from 'selectors/facet';

import moment from 'moment';

import {
  averageSentimentHelpMessage,
  differentialSentimentHelpMessage,
  volumeHelpMessage,
} from 'components/customer/v2/helpMessageV2';
import DistributionVisualization from 'components/customer/visualization/DistributionVisualization';
import SentimentVolumeTimeSeriesVisualizations from 'components/customer/visualization/SentimentVolumeTimeSeriesVisualizations';
import TopBottomPanel from 'components/customer/visualization/top-bottom-panel/TopBottomPanel';
import DashboardSeparator from 'components/ui/DashboardSeparator';
import { MediumSpacePaddedRow, SmallSpacePaddedGrid } from 'components/ui/Grid';
import Header from 'components/ui/Header';
import Segment from 'components/ui/Segment';
import ExportAsImage from 'components/ui/button/export-as/ExportAsImage';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';

import commonPropTypes from 'utils/commonPropTypes';
import {
  floatFormatter,
  numberFormatter,
  onePrecisionFloatFormatter,
} from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import DashboardCard, { selectDashboardCards } from './DashboardCard';
import DashboardKpi from './DashboardKpi';

const generalDistributionField = (field) =>
  field === 'db_ontology' ? 'ontology' : field;

const DISTRIBUTIONS_DISPLAY_OPTIONS = [
  {
    key: 'source',
    value: 'source',
    i18nText: msg({ id: 'source' }),
  },
  {
    key: 'ontology',
    value: 'db_ontology',
    i18nText: msg({ id: 'ontology' }),
  },
];

const FullWidthHeader = styled(Header)`
  &&& {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
`;

function V2Dashboard({
  viewFacet,
  maybeFetchTimeSeriesVisualizationsAggregates,
  timeSeriesVisualizations: {
    timeSeries,
    topBottomConcept,
    kpis,
    competitionKpis,
    generalDistributions,
  },
  entities,
  hidden,
  viewFacetAggregatesIsLoading,
}) {
  useLingui();
  const [displayDistributionOption, setDisplayDistributionOption] =
    useState('source');
  const [topPanelDisplayTable, setTopPanelDisplayTable] = useState(false);
  const [topPanelDisplayOption, setTopPanelDisplayOption] = useState('100Y');
  const [bottomPanelDisplayTable, setBottomPanelDisplayTable] = useState(false);
  const [bottomPanelDisplayOption, setBottomPanelDisplayOption] =
    useState('100Y');

  // entityLabelFormatter = props.entityLabelFormatter.bind(this);
  const entityLabelFormatter = useSelector(entityLabelFormatterSelector);

  useEffect(() => {
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'general_dist');
    maybeFetchTimeSeriesVisualizationsAggregates(viewFacet.id, 'ts');
    maybeFetchTimeSeriesVisualizationsAggregates(
      viewFacet.id,
      'top_bottom_concept'
    );
    maybeFetchTimeSeriesVisualizationsAggregates(
      viewFacet.id,
      'competition_kpi'
    );
  }, []);

  const onSetTopPanelDisplayTable = useCallback(
    (_, { value }) => setTopPanelDisplayTable(value),
    []
  );
  const onSetTopPanelDisplayOption = useCallback(
    (_, { value }) => setTopPanelDisplayOption(value),
    []
  );
  const onSetBottomPanelDisplayTable = useCallback(
    (_, { value }) => setBottomPanelDisplayTable(value),
    []
  );
  const onSetBottomPanelDisplayOption = useCallback(
    (_, { value }) => setBottomPanelDisplayOption(value),
    []
  );
  const onSetDisplayDistributionOption = useCallback(
    (_, { value }) => setDisplayDistributionOption(value),
    []
  );

  const getExportName = () => {
    const distributionDisplayWording = DISTRIBUTIONS_DISPLAY_OPTIONS.find(
      (display) => display.value === displayDistributionOption
    ).i18nText;
    return `${t({ id: 'distribution-by' })} ${i18n._(
      distributionDisplayWording
    )}`;
  };

  // Not sure why this is necessary - if not set, date is initially using english, even if it is globally set to another locale
  const createDate = moment(viewFacet.create_date);
  const updateDate = moment(viewFacet.aggregates_update_date);

  let differentialSentiment = '-';
  let differentialSentimentIncrease = '-';
  const isComparative = isComparativeViewFacet(viewFacet);
  if (isComparative && competitionKpis) {
    if (competitionKpis.benchmarkKpis && competitionKpis.benchmarkKpis.length) {
      differentialSentiment =
        competitionKpis.benchmarkKpis[0].average_sentiment_differential;
    }
    if (
      kpis &&
      competitionKpis.increases &&
      competitionKpis.increases['1M'] &&
      kpis.increases &&
      kpis.increases['1M']
    ) {
      differentialSentimentIncrease =
        kpis.increases['1M'][0].average_sentiment -
        competitionKpis.increases['1M'][0].average_sentiment;
    }
  }

  const selectedCards = selectDashboardCards(kpis);

  const chartId = `distribution-visualization-${viewFacet.id}`;
  const legendFormatter = (item) =>
    entityLabelFormatter(displayDistributionOption, item);
  if (hidden || !Object.keys(entities.ontologies).length) return null;
  return (
    <SmallSpacePaddedGrid>
      <MediumSpacePaddedRow style={{ width: '100%' }}>
        <Grid.Column width={4}>
          <p>
            <Trans id="analysis-updated-on" /> :{' '}
            {updateDate.format(svars.dateTimeFormat)}
          </p>
          <Header
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: svars.fontSizeBig,
            }}
          >
            {viewFacet.name}
          </Header>
          <p style={{ fontSize: svars.fontSizeSmaller }}>
            <Trans id="created-on@fem." /> :{' '}
            {createDate.format(svars.dateTimeFormat)}
          </p>
        </Grid.Column>
        <Grid.Column width={12}>
          <Grid relaxed>
            <Grid.Row>
              <Grid.Column
                width={4}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {kpis && (
                  <>
                    <DashboardKpi
                      kpiValueTestId="volume"
                      name={
                        <Trans id="volume" render={capitalizedTranslation} />
                      }
                      value={kpis.kpis ? kpis.kpis[0].n_chunks : '-'}
                      increase={
                        kpis.increases && kpis.increases['1M']
                          ? kpis.increases['1M'][0].n_chunks
                          : '-'
                      }
                      formatter={numberFormatter}
                      increaseFormatter={onePrecisionFloatFormatter}
                      valueColor={svars.volumeColor}
                      description={<Trans id={volumeHelpMessage} />}
                    />
                    <DashboardKpi
                      kpiValueTestId="sentiment"
                      withMargins
                      name={
                        <Trans id="sentiment" render={capitalizedTranslation} />
                      }
                      value={kpis.kpis ? kpis.kpis[0].average_sentiment : '-'}
                      increase={
                        kpis.increases && kpis.increases['1M']
                          ? kpis.increases['1M'][0].average_sentiment
                          : '-'
                      }
                      formatter={floatFormatter}
                      increaseFormatter={floatFormatter}
                      description={<Trans id={averageSentimentHelpMessage} />}
                      absoluteIncrease
                    />
                    <DashboardKpi
                      name={
                        <Trans
                          id="differential-sentiment"
                          render={capitalizedTranslation}
                        />
                      }
                      value={differentialSentiment}
                      increase={differentialSentimentIncrease}
                      formatter={floatFormatter}
                      increaseFormatter={floatFormatter}
                      disabled={!isComparative}
                      description={
                        <Trans id={differentialSentimentHelpMessage} />
                      }
                      absoluteIncrease
                    />
                  </>
                )}
              </Grid.Column>
              {selectedCards.map((cardItem) => (
                <Grid.Column
                  width={4}
                  stretched
                  key={cardItem.key}
                  textAlign="center"
                >
                  <DashboardCard {...cardItem} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </MediumSpacePaddedRow>

      <DashboardSeparator />
      <MediumSpacePaddedRow style={{ width: '100%' }}>
        <SentimentVolumeTimeSeriesVisualizations
          loading={viewFacetAggregatesIsLoading}
          data={timeSeries ? timeSeries.monthly : undefined}
          chartId={`ts-general-${viewFacet.id}`}
        />
        <Grid.Column width={4} stretched>
          <Segment>
            <FullWidthHeader>
              <span
                style={{
                  display: 'block',
                }}
              >
                <Trans id="distribution-by" />{' '}
                <InTextDropdown
                  placeholder={t({ id: 'select-an-axis' })}
                  options={DISTRIBUTIONS_DISPLAY_OPTIONS}
                  data-testid="bo-dropdown-chart-menu-button"
                  onChange={onSetDisplayDistributionOption}
                  value={displayDistributionOption}
                  style={{
                    minWidth: 0,
                    display: 'unset',
                  }}
                  fluid
                />
              </span>
              <ExportAsImage
                disabled={viewFacetAggregatesIsLoading}
                exportId={chartId}
                exportName={getExportName}
                exportAsImageTestId={`overview-export-as-image-icon-${chartId}`}
              />
            </FullWidthHeader>
            <DistributionVisualization
              height={svars.chartHeight}
              data={
                generalDistributions?.[
                  generalDistributionField(displayDistributionOption)
                ] || undefined
              }
              cellKey={displayDistributionOption}
              key={`dist-${displayDistributionOption}`}
              indicatorKey="n_chunks"
              legendFormatter={legendFormatter}
              tooltipExtraFieldsKeys={['average_sentiment']}
              tooltipHeaderField={{
                key: displayDistributionOption,
                isEntity: true,
                formatter: legendFormatter,
              }}
              loading={viewFacetAggregatesIsLoading}
              othersCellLabel={(nSource) => t`${nSource} extra sources`}
              chartId={chartId}
            />
          </Segment>
        </Grid.Column>
      </MediumSpacePaddedRow>
      <MediumSpacePaddedRow columns={2} style={{ paddingTop: 0 }}>
        <Grid.Column stretched>
          <Segment>
            <TopBottomPanel
              title={t({ id: 'categories-top-5' })}
              axis="top"
              conceptSelection={topBottomConcept?.top || {}}
              ontologies={entities.ontologies}
              entityLabelFormatter={entityLabelFormatter}
              loading={viewFacetAggregatesIsLoading}
              displayTable={topPanelDisplayTable}
              onToggleDisplayTable={onSetTopPanelDisplayTable}
              onDisplayOptionChange={onSetTopPanelDisplayOption}
              displayOption={topPanelDisplayOption}
              testid="categories-top-5-concepts"
            />
          </Segment>
        </Grid.Column>
        <Grid.Column stretched>
          <Segment>
            <TopBottomPanel
              title={t({ id: 'categories-bottom-5' })}
              axis="bottom"
              conceptSelection={topBottomConcept ? topBottomConcept.bottom : {}}
              ontologies={entities.ontologies}
              entityLabelFormatter={entityLabelFormatter}
              loading={viewFacetAggregatesIsLoading}
              displayTable={bottomPanelDisplayTable}
              onToggleDisplayTable={onSetBottomPanelDisplayTable}
              onDisplayOptionChange={onSetBottomPanelDisplayOption}
              displayOption={bottomPanelDisplayOption}
            />
          </Segment>
        </Grid.Column>
      </MediumSpacePaddedRow>
    </SmallSpacePaddedGrid>
  );
}

V2Dashboard.propTypes = {
  hidden: PropTypes.bool,
  viewFacet: commonPropTypes.viewFacet.isRequired,
  timeSeriesVisualizations: commonPropTypes.timeSeriesVisualizations.isRequired,
  entities: commonPropTypes.entities.isRequired,
  entityLabelFormatter: PropTypes.func.isRequired,
  maybeFetchTimeSeriesVisualizationsAggregates: PropTypes.func.isRequired,
  viewFacetAggregatesIsLoading: PropTypes.bool.isRequired,
};

V2Dashboard.defaultProps = {
  hidden: false,
};

const viewFacetAggregatesLoadingSelector = createLoadingSelector([
  viewActionTypes.FETCH_TIME_SERIES_VISUALIZATIONS_REQUEST,
]);

export default connect((state, { viewFacet }) => ({
  timeSeriesVisualizations:
    state.view.viewFacetAggregates[viewFacet?.id]?.timeSeriesVisualizations ||
    {},
  isLoading: state.ui.isLoading,
  entities: state.entities,
  viewFacetAggregatesIsLoading: viewFacetAggregatesLoadingSelector(
    state.loading
  ),
}))(V2Dashboard);
